import React from 'react';
import './BackgroundStyles.css';

const BackgroundStyles = () => {
  return (
    <section id="Home">
        
        </section>
  );
};

export default BackgroundStyles;